import { useState, useCallback, useEffect } from 'react';

function getDimensionObject(node) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

const useDimensions = (useScrollMonitor, refreshDims) => {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (node) {
      const measure = () => {
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );

        // HACK: TODO:  for now as it doesn't always grab the correct width on first render need investigation, which leaves image too small in <HeadingImageBulletsSideBySide/>
        if (refreshDims) {
          setTimeout(() => {
            window.requestAnimationFrame(() =>
              setDimensions(getDimensionObject(node))
            );
          }, 500);
          setTimeout(() => {
            window.requestAnimationFrame(() =>
              setDimensions(getDimensionObject(node))
            );
          }, 1000);
        }
      };
      measure();

      window.addEventListener('resize', measure);
      if (useScrollMonitor) {
        window.addEventListener('scroll', measure);
      }

      return () => {
        window.removeEventListener('resize', measure);
        if (useScrollMonitor) {
          window.removeEventListener('scroll', measure);
        }
      };
    }
  }, [ref, node, useScrollMonitor]);

  return [ref, dimensions, node];
};

export default useDimensions;
