import React from 'react';
import { withTheme } from 'styled-components';
import { Image } from '@components/Images';

import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { Section } from '@components/Layout';
import { BulletList } from '@components/Blocks';
import { H2, Body1, Bold, Body2 } from '@components/Text';
import HeadingImageBulletsSideBySide from '@components/Blocks/HeadingImageBulletsSideBySide';

import phone1_2x from '@public/images/phone1@2x.png';

const BulletItems = () => {
  return (
    <BulletList
      listItems={[
        <Body1 key="convo-item1">
          Discuss pros and cons of stocks with thought leaders and people you trust; learn more every day
        </Body1>,
        <Body1 key="convo-item2">
          See what stocks people are buying and selling and compare to your own portfolio
        </Body1>,
        <Body1 key="convo-item3">
          Have 1-on-1 Q and A with thought leaders when you want an experienced perspective
        </Body1>,
      ]}
    />
  );
};

const ConversationsFeatureMobile = () => {
  return (
    <Section>
      <Column className="section-container" padding="150px 0 0 0" hAlign="center">
        <Place width="100%" maxWidth="600px">
          <H2>
            <Bold>
              Join
              <br />
              conversations
            </Bold>
            <br /> with leading <br />
            investors{' '}
          </H2>
          <Row padding="40px 0 0 0">
            <Body2>
              See what thought leaders and your community are investing in to make your own informed decisions.{' '}
            </Body2>
          </Row>
          <Row padding="50px 0 50px" hAlign="center">
            <Image src={phone1_2x} alt="" />
          </Row>
          <BulletItems />
        </Place>
      </Column>
    </Section>
  );
};

const ConversationsFeatureFull = () => {
  return (
    <Section>
      <Column className="section-container" width="100%" hAlign="center" padding="0 0 144px 0">
        <div style={{ maxWidth: '1100px' }}>
          <HeadingImageBulletsSideBySide
            headingComponent={
              <H2>
                <Bold>Join conversations</Bold>
                <br /> with leading <br />
                investors{' '}
              </H2>
            }
            subHeadingComponent={
              <Body1>
                See what thought leaders and your community are
                <br /> investing in to make your own informed decisions.{' '}
              </Body1>
            }
            bulletsComponent={<BulletItems />}
            imageData={{
              src: phone1_2x,
            }}
            isImageLeft={false}
          />
        </div>
      </Column>
    </Section>
  );
};

const ConversationsFeatureMain = ({ theme }) => {
  const { isMobile } = theme;
  return isMobile ? <ConversationsFeatureMobile /> : <ConversationsFeatureFull />;
};

export default withTheme(ConversationsFeatureMain);
