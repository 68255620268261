import React from 'react';
import { Column, Place, Row } from '@lib/nvstr-common-ui.es';
import { Bold, Eyebrow, H3 } from '@components/Text';
import Link from 'next/link';
import { PATHS } from '@constants/paths';
import { TrackingEvents } from '../../tracking';
import Anchor from '@components/Primitives/Anchor';
import Button3 from '@components/Buttons/Button3';

const FinraLink = ({ isMobile }) => {
  const onClick = () => {
    TrackingEvents.aboutUs.clickFINRAButton.send();
  };
  const width = isMobile ? '100%' : '426px';
  return (
    <Link href={PATHS.FINRA} className={'no-text-decoration'}>
      <Button3 width={width} onClick={onClick}>
        FIND OUT MORE ABOUT US FROM FINRA
      </Button3>
    </Link>
  );
};

export const FDIC = () => {
  return (
    <Column position="relative">
      <Row>
        <H3>
          We are registered&nbsp;with <Bold>FINRA&nbsp;and&nbsp;SEC, and a member&nbsp;of&nbsp;SIPC</Bold>
        </H3>
      </Row>
      <Row padding="48px 0 0 0">
        <Column>
          <Row>
            <Column>
              <Row>
                <H3>CASH SWEEP DEPOSITS ARE INSURED UP TO $5&nbsp;MILLION BY FDIC.</H3>
              </Row>
            </Column>
          </Row>
          <Row padding="48px 0 0 0">
            <H3>
              UP&nbsp;TO $37.5&nbsp;MILLION INSURANCE&nbsp;WITH <Bold>Lloyds&nbsp;of London&nbsp;*</Bold>
            </H3>
          </Row>

          <Row padding="24px 0 0 0" maxWidth={'1000px'}>
            <Eyebrow>
              * Tornado&apos;s CLEARING FIRM OFFERS UP TO $5 MILLION OF FDIC INSURANCE THROUGH A NETWORK OF
              PARTICIPATING BANKS. IT ALSO HAS INSURANCE ABOVE THE SIPC LIMITS, WITH COVERAGE OF $150 MILLION IN
              AGGREGATE, INCLUDING UP TO $37.5 MILLION FOR ANY SINGLE CUSTOMER'S SECURITIES AND $900K FOR ANY SINGLE
              CUSTOMER'S CASH HOLDINGS. See{' '}
              <Bold>
                <Link href={PATHS.FAQ + '?section=security'}>here</Link>
              </Bold>{' '}
              for details.
            </Eyebrow>
          </Row>
        </Column>
      </Row>
    </Column>
  );
};
