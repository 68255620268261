import React from 'react';
import { withTheme } from 'styled-components';
import { Image } from '@components/Images';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { Section } from '@components/Layout';
import { BulletList } from '@components/Blocks';
import { H2, Body1, Bold } from '@components/Text';
import HeadingImageBulletsSideBySide from '@components/Blocks/HeadingImageBulletsSideBySide';

import phone2_2x from '@public/images/phone2@2x.png';

const BulletItems = () => {
  return (
    <BulletList
      listItems={[
        <Body1 key="news-item1">Save time with a one-stop shop feed of live news from reputable sources</Body1>,
        <Body1 key="news-item2">Dive deeper on any stock to see the latest news, metrics, and pros and cons</Body1>,
        <Body1 key="news-item3">Add any stock to your watchlist to keep closer tabs</Body1>,
      ]}
    />
  );
};

const NewsFeatureMobile = () => {
  return (
    <Section>
      <Column className="section-container" padding="150px 0 0 0" hAlign="center">
        <Place width="100%" maxWidth="600px">
          <H2>
            <Bold>
              Get news
              <br />
              Tailored{' '}
            </Bold>
            to <br />
            your stocks
          </H2>
          <Row padding="40px 0 0 0">
            <Body1>
              Stay smart on the markets with real-time news
              <br /> hyper-tailored to your interests and investments.
            </Body1>
          </Row>
          <Row padding="50px 0 50px" hAlign="center">
            <Image src={phone2_2x} alt="" />
          </Row>
          <BulletItems />
        </Place>
      </Column>
    </Section>
  );
};

const NewsFeatureFull = () => {
  return (
    <Section>
      <Column className="section-container" width="100%" hAlign="center" padding="0 0 144px 0">
        <div style={{ maxWidth: '1100px' }}>
          <HeadingImageBulletsSideBySide
            headingComponent={
              <H2>
                <Bold>
                  Get news
                  <br />
                  Tailored
                </Bold>{' '}
                to
                <br />
                your stocks
              </H2>
            }
            subHeadingComponent={
              <Body1>
                Stay smart on the markets with real-time news
                <br /> hyper-tailored to your interests and investments.
              </Body1>
            }
            bulletsComponent={<BulletItems />}
            imageData={{
              src: phone2_2x,
            }}
            isImageLeft={true}
          />
        </div>
      </Column>
    </Section>
  );
};

const NewsFeatureMain = ({ theme }) => {
  const { isMobile } = theme;
  return isMobile ? <NewsFeatureMobile /> : <NewsFeatureFull />;
};

export default withTheme(NewsFeatureMain);
