export const parseQueryString = function (query) {
  if (!query || query.length === 0) {
    return {};
  }
  const keyValues = query.split('?')[1];
  const keyValuesArray = keyValues.split('&');
  const parsedQuery = {};
  keyValuesArray.forEach(function (string) {
    const key = string.split('=')[0];
    const value = string.split('=')[1];
    parsedQuery[key] = value;
  });
  return parsedQuery;
};
