import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span``;

function YahooFinance() {
  return (
    <Wrapper>
      <svg viewBox="0 0 145 53">
        <title>{'finance_plus'}</title>
        <g fillRule="nonzero" fill="none">
          <path
            d="M134.83 0l-8.259 19.945h9.226L144.057 0h-9.226zm-11.07 27.107c0 2.767 2.152 5.052 5.052 5.052 2.987 0 5.228-2.372 5.228-5.228 0-2.812-2.153-5.052-5.052-5.052-2.988 0-5.228 2.416-5.228 5.228zm-8.348-6.327c0-2.46-1.845-4.437-4.393-4.437s-4.393 1.977-4.393 4.437 1.845 4.437 4.393 4.437 4.393-1.976 4.393-4.437zm-24.866 0c0-2.46-1.845-4.437-4.393-4.437s-4.394 1.977-4.394 4.437 1.846 4.437 4.394 4.437 4.393-1.976 4.393-4.437zm-53.335 4.525c-2.592 0-4.569-1.977-4.569-4.525s1.977-4.525 4.57-4.525c2.548 0 4.525 1.977 4.525 4.525s-1.977 4.525-4.526 4.525zM0 9.753L9.402 31.94l-3.383 7.995h8.26l12.52-30.182h-8.215L13.487 22.67 8.435 9.753H0zM97.927 20.78c0 6.59-5.052 11.555-11.774 11.555-6.722 0-11.774-4.965-11.774-11.555S79.43 9.226 86.153 9.226c6.722 0 11.774 4.965 11.774 11.554zm24.866 0c0 6.59-5.052 11.555-11.774 11.555-6.722 0-11.774-4.965-11.774-11.555s5.052-11.554 11.774-11.554c6.722 0 11.774 4.965 11.774 11.554zM51.665 0v31.763h7.776v-11.73c0-2.284 1.099-3.646 2.856-3.646 1.714 0 2.68 1.186 2.68 3.383v11.993h7.776V17.924c0-5.359-2.9-8.698-7.556-8.698-2.504 0-4.438.966-5.8 2.68V0h-7.732zM41.561 9.753v2.065c-1.187-1.538-3.383-2.592-5.931-2.592-6.019 0-10.5 5.316-10.5 11.554 0 6.415 4.437 11.555 10.5 11.555 2.548 0 4.744-1.01 5.93-2.636v2.064h7.514V9.753H41.56z"
            fill="#222"
          />
          <path
            d="M126.928 44.727c.1-1.023.973-1.697 2.121-1.697 1.148 0 1.996.674 2.096 1.697h-4.217zm4.292 3.469c-.55.723-1.173 1.023-2.071 1.023-1.198 0-2.171-.773-2.246-1.897h8.61c.024-.224.049-.548.049-.948 0-4.092-2.645-6.788-6.488-6.788-3.718 0-6.613 2.82-6.613 6.613 0 3.968 2.595 6.564 6.663 6.564 2.745 0 4.791-1.049 5.989-3.07l-3.893-1.497zm-19.265-2.022c0 4.043 2.895 6.589 6.663 6.589.948 0 2.021-.2 2.994-.7v-4.142c-.698.5-1.471.749-2.27.749-1.598 0-2.77-.998-2.77-2.496 0-1.496 1.198-2.495 2.77-2.495.799 0 1.547.225 2.27.699v-4.142c-.873-.425-1.946-.65-2.919-.65-3.943 0-6.738 2.77-6.738 6.588zm-35.236 6.24h4.567v-7.911c0-3.045-1.672-4.917-4.342-4.917-1.472 0-2.67.624-3.394 1.797v-1.447h-4.566v12.477h4.566v-6.638c0-1.272.624-2.046 1.622-2.046.973 0 1.547.699 1.547 1.896v6.788zm29.422 0h4.566v-7.911c0-3.045-1.671-4.917-4.342-4.917-1.472 0-2.67.624-3.393 1.797v-1.447h-4.567v12.477h4.567v-6.638c0-1.272.624-2.046 1.622-2.046.973 0 1.547.699 1.547 1.896v6.788zm-14-6.24c0 1.398-1.123 2.521-2.57 2.521-1.447 0-2.57-1.123-2.57-2.52 0-1.398 1.123-2.52 2.57-2.52 1.447 0 2.57 1.122 2.57 2.52zm-.125-6.238v1.323c-.773-1.073-2.12-1.673-3.618-1.673-3.394 0-5.864 2.92-5.864 6.588 0 3.694 2.446 6.589 5.864 6.589 1.498 0 2.82-.6 3.618-1.673v1.323h4.492V39.936h-4.492zm-24.654-3.669c0-1.347-1.123-2.52-2.57-2.52-1.398 0-2.546 1.148-2.546 2.52 0 1.373 1.148 2.521 2.545 2.521 1.448 0 2.571-1.173 2.571-2.52zm-4.841 16.146h4.566V39.936h-4.566v12.477zm-4.642-12.776c0-1.323.824-1.897 1.871-1.897.55 0 .999.125 1.373.3V34.27c-.549-.274-1.373-.424-2.196-.424-3.518 0-5.59 2.17-5.59 5.69v.4h-1.671v3.667h1.672v8.81h4.541v-8.81h3.07v-3.668h-3.07v-.3z"
            fill="#666"
            opacity={0.7}
          />
        </g>
      </svg>
    </Wrapper>
  );
}

export default YahooFinance;
