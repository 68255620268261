import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Row, Place, Column } from '@lib/nvstr-common-ui.es';
import { Section } from '@components/Layout';
import { H2, H3, H6, Body1, Bold, Body2 } from '@components/Text';
import { RoundBox } from '@components/Blocks';
import useDimensions from 'hooks/useDimensions';
import { YellowRadial } from '@components/Backgrounds';
import { PATHS } from '@constants/paths';
import { commissionFreeTradeAmount, membershipPrice } from '@constants/index';
import { IS_WHITE_LABELED } from '../../appConfig';
import { FDIC } from '@components/Content/FDIC';

const YellowRadialWrapper = styled.div`
  svg {
    width: 1000px;
    height: 1000px;
  }

  circle {
    opacity: 0.55;
  }

  @media (max-width: 999px) {
    svg {
      width: 1500px;
      height: 1500px;
    }

    circle {
      opacity: 0.55;
    }
  }
`;

const DesktopWrapper = styled.div`
  overflow: hidden;

  h3 {
    line-height: 92%;
  }

  @media (max-width: 1125px) {
    h3,
    h6 {
      font-size: 0.8em;
      line-height: 120%;
    }
    h3 {
      line-height: 92%;
    }
  }
`;

const FDICWrapper = styled.div`
  padding-top: 80px;

  max-width: 625px;
  margin: 0 auto;

  @media (max-width: 600px) {
    h2,
    h3 {
      font-size: 1em !important;
      line-height: 95% !important;
    }
  }

  @media (max-width: 420px) {
    h2,
    h3 {
      font-size: 0.8em !important;
      line-height: 95% !important;
    }
  }
`;

const MobileWrapper = styled.div`
  overflow: hidden;

  ${RoundBox} {
    h3 {
      font-size: 3em;
      line-height: 85%;
    }

    @media (max-width: 600px) {
      h3 {
        font-size: 2em;
        line-height: 85%;
      }
    }
  }

  @media (max-width: 600px) {
    h2,
    h3 {
      font-size: 2em;
      line-height: 85%;
    }
  }

  @media (max-width: 360px) {
    h2,
    h3 {
      font-size: 1.4em;
      line-height: 85%;
    }
  }
`;

const PricingMobile = () => {
  return (
    <MobileWrapper>
      <Section>
        <Column
          className="section-container"
          padding="150px 0 100px 0"
          hAlign="center"
          width="100%"
          style={{ overflow: 'hidden' }}
        >
          {IS_WHITE_LABELED ? (
            <></>
          ) : (
            <Place position="absolute" bottom="-200px" right="-1100px" zIndex="-1">
              <YellowRadialWrapper>
                <YellowRadial />
              </YellowRadialWrapper>
            </Place>
          )}
          <Place maxWidth="625px">
            <Row>
              <H2>
                <Bold>1 clear price.</Bold>
                <br /> No funny business.
              </H2>
            </Row>
            <Row padding="40px 0 0 0">
              <Body2>We don’t profit from encouraging excessive, gamified trading.</Body2>
            </Row>
            <Column padding="50px 0 0 0">
              <RoundBox height="275px" padding="10%" margin="0 0 30px 0">
                <Row vAlign="bottom" hAlign="center" height="100%">
                  <H3>
                    <Bold>${membershipPrice}</Bold>
                  </H3>
                  <H6>/month</H6>
                </Row>
              </RoundBox>
              <RoundBox height="275px" padding="10%" margin="0 0 30px 0">
                <Row vAlign="bottom" hAlign="center" height="100%">
                  <H3>
                    <Bold>{commissionFreeTradeAmount}</Bold>
                  </H3>
                  <Place left="20px" maxWidth="200px">
                    <H6>commission-free trades</H6>
                  </Place>
                </Row>
              </RoundBox>
              <RoundBox height="275px" padding="10%" margin="0 0 30px 0">
                <Row vAlign="bottom" hAlign="center" height="100%">
                  <H6>Additional trades, AND trades without a subscription are $4.50</H6>
                </Row>
              </RoundBox>
            </Column>
          </Place>
          <Row padding="60px 0 0 0">
            <a href={PATHS.FINRA} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Body1>
                Find out more about us on <Bold>FINRA BrokerCheck</Bold>
              </Body1>
            </a>
          </Row>
          <FDICWrapper>
            <FDIC />
          </FDICWrapper>
        </Column>
      </Section>
    </MobileWrapper>
  );
};

const PricingFull = () => {
  const [ref, dimensions] = useDimensions();
  const { width } = dimensions;

  const margin = 28 * 2;
  const boxWidth = (width - margin) / 3;

  return (
    <DesktopWrapper>
      <Section position="relative">
        <Column className="section-container" padding="176px 100px 216px 100px">
          <div ref={ref} style={{ paddingTop: '48px' }}>
            <H3>
              <Bold>1 clear price.</Bold>
              <br /> No funny business.
            </H3>
            <Row padding="40px 0 0 0">
              <Body1>We don’t profit from encouraging excessive, gamified trading.</Body1>
            </Row>
            <Row padding="100px 0 0 0">
              <RoundBox width={`${boxWidth}px`} height="268px" padding="50px 36px">
                <Row vAlign="bottom" height="100%">
                  <H3>
                    <Bold>${membershipPrice}</Bold>
                  </H3>
                  <div style={{ paddingBottom: '2px' }}>
                    <H6> /month</H6>
                  </div>
                </Row>
              </RoundBox>
              <RoundBox width={`${boxWidth}px`} height="268px" margin="0 0 0 28px" padding="50px 36px">
                <Row vAlign="bottom" height="100%">
                  <H3>
                    <Bold>{commissionFreeTradeAmount}</Bold>
                  </H3>
                  <Place left="20px">
                    <H6>commission-free trades</H6>
                  </Place>
                </Row>
              </RoundBox>
              <RoundBox width={`${boxWidth}px`} height="268px" margin="0 0 0 28px" padding="50px 36px">
                <Row vAlign="bottom" height="100%">
                  <H6>Additional trades, AND trades without a subscription are $4.50</H6>
                </Row>
              </RoundBox>
            </Row>
          </div>
          <Row padding="100px 0 0 0">
            <a href={PATHS.FINRA} target="_blank" rel="noopener noreferrer " style={{ textDecoration: 'none' }}>
              <Body1>
                Find out more about us on <Bold>FINRA BrokerCheck</Bold>
              </Body1>
            </a>
          </Row>
          <Row padding="100px 0 0 0">
            <FDIC />
          </Row>
        </Column>
        {IS_WHITE_LABELED ? (
          <></>
        ) : (
          <Place position="absolute" bottom="-200px" right="-520px" zIndex="-1">
            <YellowRadialWrapper>
              <YellowRadial />
            </YellowRadialWrapper>
          </Place>
        )}
      </Section>
    </DesktopWrapper>
  );
};

const PricingMain = ({ theme }) => {
  const { isMobile } = theme;
  return isMobile ? <PricingMobile /> : <PricingFull />;
};

export default withTheme(PricingMain);
