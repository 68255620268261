import React from 'react';
import { parseQueryString } from '@utils/usefulFuncs';

import styled from 'styled-components';
import { Image } from '@components/Images';
import { H3, Disclaimer, Body1 } from '@components/Text';

import { contentfulClient } from '@utils/apolloClients';
import { useQuery, gql } from '@apollo/client';
import { isDev } from '@utils/isDev';

const GET_HERO = gql`
  query LearnAndEarnHero($preview: Boolean, $where: LearnAndEarnHeroFilter) {
    learnAndEarnHeroCollection(preview: $preview, where: $where) {
      items {
        title
        headline
        copy
        heroImage {
          title
        }
        disclaimers
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  // min-height: 400px;
  padding: 0;
  @media (max-width: 800px) {
    padding: 60px 7.5% 0;
  }
`;

const SubGrid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-templates-rows: auto;
  grid-template-areas: 'img copy';
  max-width: 1440px;
  padding: 60px 100px 60px;
  @media (max-width: 1200px) {
    padding: 20px 40px 40px;
  }
  @media (max-width: 999px) {
    padding: 20px 10% 20px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'copy' 'img';
    padding: 20px 0% 20px;
  }
`;

const CopyBlock = styled.div`
  grid-area: copy;
  align-self: flex-end;
  width: 80%;
  padding: 0 0 30px;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Img = styled.div`
  grid-area: img;
  align-self: flex-end;
`;

const CopyWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 20px 0 0;
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

const Disclaimers = styled.div`
  margin: 0 auto;
  padding: 0 100px 60px;
  max-width: 1400px;
  @media (max-width: 1200px) {
    padding: 0 40px 40px;
  }
  @media (max-width: 999px) {
    padding: 0 10% 20px;
  }
  @media (max-width: 800px) {
    padding: 0 0% 20px;
  }
`;

export default function LearnAndEarnHero({ heroTitle, variants }) {
  let displayVariant = null;

  const query = parseQueryString(window.location.search);

  variants &&
    variants.map((variant, i) => {
      if (query.leVariant === `${i}`) {
        displayVariant = variant;
      } else {
        displayVariant = variants[0];
      }
    });

  const { data, loading, error } = useQuery(GET_HERO, {
    variables: {
      where: { title: heroTitle ? heroTitle : displayVariant },
      preview: isDev,
    },
    client: contentfulClient,
  });

  if (loading) {
    return null;
  }
  if (error) {
    console.error(error);
    return null;
  }

  const heroQueryResult = data.learnAndEarnHeroCollection.items;
  const heroImageTitle = heroQueryResult[0].heroImage.title;
  const heroHeadline = heroQueryResult[0].headline;
  const heroCopy = heroQueryResult[0].copy;
  const heroDisclaimers = heroQueryResult[0].disclaimers;

  return (
    <Wrapper>
      <SubGrid>
        <Img>
          <Image useNextImage={true} imageTitle={heroImageTitle} />
        </Img>
        <CopyBlock>
          <H3>
            {/* <span dangerouslySetInnerHTML={{ __html: `${heroHeadline}` }} /> */}
            <b>Earn up to $25 to invest</b> when you level up your skills
          </H3>
          <CopyWrapper>
            {/* <span dangerouslySetInnerHTML={{ __html: `${heroCopy}` }} /> */}
            <Body1>
              Unlock new <b>Learn and Earn</b> lessons at every milestone and Tornado will continue to reward your
              progress.
            </Body1>
          </CopyWrapper>
        </CopyBlock>
      </SubGrid>
      {heroDisclaimers && (
        <Disclaimers>
          <Disclaimer>
            {/* <span dangerouslySetInnerHTML={{ __html: `${heroDisclaimers}` }} /> */}
            Tornado brokerage account holders may earn up to $25 in rewards by completing Learn & Earn lessons. Valid
            bank account link and/or qualifying deposit may be required. Rewards can be invested right away and
            withdrawn after one year. Other terms and conditions apply. See the{' '}
            <a href="https://tornado.com/learn-and-earn-rules">Official Rules</a> for details.
          </Disclaimer>
        </Disclaimers>
      )}
    </Wrapper>
  );
}
