import React from 'react';
import styled from 'styled-components';
import { Layout } from '@components/Layout';
import { colorPalette } from '@lib/nvstr-utils.es';
import { Pricing } from '@components/Content';
import Hero_wl from '@components/Content/WhiteLabled/Hero_wl';
import FeatureSection from '@components/Content/WhiteLabled/FeatureSection';
import DetailedFeatureSection from '@components/Content/WhiteLabled/DetailedFeatureSection';

const Wrapper = styled.div`
  background: ${colorPalette.secondary.white};
  overflow: hidden;

  .section-container {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (max-width: 1200px) {
    .section-container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media (max-width: 999px) {
    .section-container {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media (max-width: 600px) {
    .section-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (min-width: 650px) and (max-width: 999px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.4em;
      line-height: 110%;
    }
  }

  @media (max-width: 366px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const Home = ({ SessionMessageComponent }) => {
  return (
    <Layout section="home" headerBgColor={'#f7f5f5'}>
      <Wrapper>
        {SessionMessageComponent}
        <div style={{ position: 'relative', zIndex: 3 }}>
          <div style={{ background: 'linear-gradient(180deg, #f7f5f5 0%, #f7f5f5 86%, #f7f5f5 100%)' }}>
            <Hero_wl />
          </div>
          <FeatureSection />
          <DetailedFeatureSection />
          <Pricing />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Home;
