import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Section } from '@components/Layout';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { H3, Body1, Body2, Bold } from '@components/Text';
import { useWindowSize } from '../../hooks/useWindowSize';
import { parseQueryString } from '@utils/usefulFuncs';
import { Image } from '@components/Images';

import learnAndEarnPhone1 from '@public/images/learnAndEarnPhone1.png';

const MobileHeadingWrapper = styled.div`
  video {
    min-height: 100%;
    min-width: 100%;
    height: auto !important;
    width: auto !important;
    max-width: 100%;
  }

  @media (min-width: 500px) {
    h2 {
      font-size: 1.5em;
      line-height: 120%;
    }

    p {
      font-size: 1.3em;
      line-height: 120%;
    }
  }

  @media (max-width: 625px) {
    .heading-wrapper {
      max-width: 500px;
    }
  }

  @media (max-width: 425px) {
    .heading-wrapper {
      max-width: 360px;

      p {
        line-height: 140%;
      }
    }
  }

  @media (max-width: 360px) {
    h2 {
      font-size: 0.8em;
      line-height: 120%;
    }

    p {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const LearnAndEarnOverviewMobile = ({ imageTitle }) => {
  return (
    <Section>
      <Column padding="50px 20px 70px" maxWidth="100%">
        <MobileHeadingWrapper>
          <div className="heading-wrapper">
            <Column maxWidth="600px" margin="0 auto">
              <H3>
                <Bold>Earn up to $25 to invest</Bold> when you level up your skills
              </H3>

              <Place padding="8px 0 0 0" width="90%" zIndex="1">
                <Body2>
                  Unlock new <b>Learn and Earn</b> lessons at every milestone and Tornado will continue to reward your
                  progress.
                </Body2>
              </Place>
            </Column>
          </div>

          <div
            style={{
              position: 'relative',
              maxWidth: '660px',
              margin: '0 auto',
              padding: '40px 0 0',
            }}
          >
            {imageTitle ? (
              <Image useNextImage={true} imageTitle={imageTitle} />
            ) : (
              <Image src={learnAndEarnPhone1} alt="" useNextImage={true} />
            )}
          </div>
        </MobileHeadingWrapper>
      </Column>
    </Section>
  );
};

const LearnTextWrapper = styled(Place)`
  @media (max-width: 1080px) {
    h3 {
      font-size: 0.84em;
      line-height: 1.1;
    }
  }
`;
const LearnAndEarnOverviewFull = ({ windowWidth, imageTitle }) => {
  const imageWidth = Math.max(windowWidth / 2, 540);

  return (
    <Section>
      <Row position="relative" style={{ margin: '0 0 90px', padding: '70px 0 10px' }}>
        <Place
          position="relative"
          zIndex="2"
          width={`${imageWidth}px`}
          height="auto"
          style={{ maxWidth: '800px', flexShrink: 0 }}
        >
          {imageTitle ? (
            <Image useNextImage={true} alt="" imageTitle={imageTitle} />
          ) : (
            <Image src={learnAndEarnPhone1} alt="" useNextImage={true} />
          )}
        </Place>

        <LearnTextWrapper
          zIndex="1"
          style={{ flexShrink: 1, maxWidth: '630px', padding: '70px 80px 10px 0' }}
          width={`${windowWidth - imageWidth}px`}
        >
          <H3>
            <Bold>Earn up to $25 to invest</Bold> when you level up your skills
          </H3>

          <Place padding="50px 0 50px" style={{ maxWidth: '500px' }}>
            <Body1>
              Unlock new <b>Learn and Earn</b> lessons at every milestone and Tornado will continue to reward your
              progress.
            </Body1>
          </Place>
        </LearnTextWrapper>
      </Row>
    </Section>
  );
};

const LearnAndEarnOverview = ({ theme, imageTitle }) => {
  const [showAppStoreLinks, setShowAppStoreLinks] = React.useState(false);
  const { isMobile } = theme;
  const { width, height } = useWindowSize();

  React.useEffect(() => {
    const query = parseQueryString(window.location.search);
    if (query.appStoreCfg != 2) {
      setShowAppStoreLinks(true);
    }
  }, []);

  return (
    <Section>
      {isMobile ? (
        <LearnAndEarnOverviewMobile
          theme={theme}
          windowWidth={width}
          windowHeight={height}
          showAppStoreLinks={showAppStoreLinks}
          imageTitle={imageTitle}
        />
      ) : (
        <LearnAndEarnOverviewFull theme={theme} windowWidth={width} windowHeight={height} imageTitle={imageTitle} />
      )}
    </Section>
  );
};

export default withTheme(LearnAndEarnOverview);
