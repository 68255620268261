import styled, { withTheme } from 'styled-components';

const RoundBox = styled.div`
  border: 2px solid ${(props) => props.theme.primary.charcoal};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};
  border-radius: 16px;
  position: relative;
`;

export default withTheme(RoundBox);
