import React from 'react';
import { withTheme } from 'styled-components';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { Eyebrow, Bold } from '@components/Text';

const BulletList = ({ listItems, theme }) => {
  return listItems.map((item, i) => (
    <Column padding="0 0 0 0" key={`bullet-item-${i}`}>
      <Row
        key={`${i}-bulletlist`}
        border={`1px solid ${theme.primary.oak}`}
        borderStyle="solid none none none"
        padding="30px 0 30px 50px"
        position="relative"
      >
        <Place position="absolute" top="35px" left="10px">
          <Eyebrow>
            <Bold>0{i + 1}</Bold>
          </Eyebrow>
        </Place>
        {item}
      </Row>
    </Column>
  ));
};

export default withTheme(BulletList);
