import React from 'react';
import styled from 'styled-components';
import Img from 'next/legacy/image';
import ImageCMS from './ImageCMS';

const Wrapper = styled.div``;

function Image({ src, width, height, alt, imageTitle, useNextImage }) {
  if (useNextImage) {
    const defaultProps = {};
    if (imageTitle) {
      return <ImageCMS imageTitle={imageTitle} />;
    } else {
      if (height) {
        defaultProps.width = width;
        defaultProps.height = height;
      }
      return <Img src={src} alt={alt} quality={80} unoptimized={true} {...defaultProps} />;
    }
  } else {
    return (
      <Wrapper>
        <img src={src} alt={alt} height={height} width={width} />
      </Wrapper>
    );
  }
}

export default Image;
