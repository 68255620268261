import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Section } from '@components/Layout';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { H2, Body1, Body2, Bold } from '@components/Text';
import { Image } from '@components/Images';
import heroImage from '@public/images/ts_pro-con-feed.png';
import { BulletList } from '@components/Blocks';

const MobileHeadingWrapper = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding: 48px 16px 0 16px;

  h2 {
    * {
      text-transform: uppercase;
      font-size: 36px;
      line-height: 44px;
    }
  }

  video {
    min-height: 100%;
    min-width: 100%;
    height: auto !important;
    width: auto !important;
    max-width: 100%;
  }

  @media (max-width: 550px) {
    h2 {
      * {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  @media (max-width: 625px) {
    .heading-wrapper {
      max-width: 500px;
    }
  }

  @media (max-width: 425px) {
    .heading-wrapper {
      max-width: 360px;

      p {
        line-height: 140%;
      }
    }
  }

  @media (max-width: 360px) {
    h2 {
      font-size: 0.8em;
      line-height: 120%;
    }

    p {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const HeroImageWrapper = styled.div`
  padding-top: 40px;
  max-width: 540px;

  @media (max-width: 1200px) {
    margin-right: 40px;
  }
`;

const MobileHeroImageWrapper = styled.div`
  padding-top: 16px;
  max-width: 540px;
  margin: 0 auto;
`;

const FullWrapper = styled.div`
  position: relative;
  padding: 80px 40px 40px 40px;

  h2 {
    font-size: 1em;
    line-height: 1;

    * {
      font-size: 48px;
      line-height: 56px;
      text-transform: uppercase;
    }
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1080px;
`;

const HeadingWrapper = styled.div`
  padding-right: 200px;
  z-index: 2;

  @media (max-width: 1160px) {
    max-width: 890px;
    padding-right: 0px;
  }
`;

const ImageSectionWithBulletsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const BulletsWrapper = styled.div`
  padding-top: 160px;
  max-width: 400px;
`;

const BulletItems = () => {
  const details = [
    'Discuss pros and cons of stocks with thought leaders and people you trust; learn more every day',
    'See what stocks people are buying and selling and compare to your own portfolio',
    'Have 1-on-1 Q&A with thought leaders when you want an experienced perspective',
  ];
  return (
    <BulletList
      listItems={details.map((t) => (
        <Body1 key={t}>{t}</Body1>
      ))}
    />
  );
};

const Full = ({ heading, subHeading, imageSrc }) => {
  return (
    <FullWrapper>
      <Wrapper>
        <HeadingWrapper>
          <h2>
            <Bold>{heading}</Bold>
          </h2>
          <Place padding="16px 164px 16px 0">
            <Body1>{subHeading}</Body1>
          </Place>
        </HeadingWrapper>
        <ImageSectionWithBulletsWrapper>
          <HeroImageWrapper>
            <Image src={imageSrc} alt="" useNextImage={true} />
          </HeroImageWrapper>
          <BulletsWrapper>
            <BulletItems />
          </BulletsWrapper>
        </ImageSectionWithBulletsWrapper>
      </Wrapper>
    </FullWrapper>
  );
};

const Mobile = ({ heading, subHeading, imageSrc }) => {
  return (
    <MobileHeadingWrapper>
      <div className="heading-wrapper">
        <Place zIndex="1" width="100%">
          <h2>
            <Bold>{heading}</Bold>
          </h2>
        </Place>

        <Place padding="8px 0 0 0" width="90%" zIndex="1">
          <Body1>{subHeading}</Body1>
        </Place>
      </div>

      <div
        style={{
          position: 'relative',
          maxWidth: '660px',
          margin: '0 auto',
        }}
      >
        <MobileHeroImageWrapper>
          <Image src={imageSrc} alt="" useNextImage={true} />
        </MobileHeroImageWrapper>
      </div>
    </MobileHeadingWrapper>
  );
};

const DetailedFeatureSection = ({ theme }) => {
  const { isMobile } = theme;
  const imageSrc = heroImage;
  const heading = <>See VALUABLE insights from Wall&nbsp;street&nbsp;pros</>;
  const subHeading =
    'Access experienced Wall Street veterans who post daily, exclusively on TheStreet powered by Tornado, plus ask them questions about stocks and get their direct feedback.';

  return (
    <Section>
      {isMobile ? (
        <Mobile theme={theme} heading={heading} subHeading={subHeading} imageSrc={imageSrc} />
      ) : (
        <Full theme={theme} heading={heading} subHeading={subHeading} imageSrc={imageSrc} />
      )}
    </Section>
  );
};

export default withTheme(DetailedFeatureSection);
