import React from 'react';

import { Banner } from '@components/Layout';
import { Eyebrow } from '@components/Text';
import Anchor from '@components/Primitives/Anchor';

const BannerBlog = () => {
  return (
    <Anchor href="https://blog.tornado.com/always-invest-better-than-yesterday-fc7b9e9a5694">
      <Banner>
        <Eyebrow>NVSTR IS NOW TORNADO</Eyebrow>
      </Banner>
    </Anchor>
  );
};

export default BannerBlog;
