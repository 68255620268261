import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span``;

function Bloomberg() {
  return (
    <Wrapper>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="&ns_svg;"
        xmlnsXlink="&ns_xlink;"
        width="300.005"
        height="60.469"
        viewBox="0 0 300.005 60.469"
        overflow="visible"
        enableBackground="new 0 0 300.005 60.469"
        xmlSpace="preserve"
      >
        <title>{'bloomberg'}</title>
        <g>
          <path
            fill="#010101"
            d="M7.897,7.813h6.52c5.959,0,8.585,1.883,8.585,5.708c0,3.324-2.626,5.581-7.897,5.581H7.897V7.813z M0,45.423
	h15.98c8.398,0,16.796-3,16.796-12.657c0-5.708-4.002-10.09-9.579-10.724V21.91c4.563-1.367,7.71-4.69,7.71-9.706
	c0-8.088-6.903-11.157-13.61-11.157H0V45.423z M7.897,25.868h7.582c6.392,0,9.411,1.632,9.411,6.274
	c0,5.821-5.654,6.515-9.726,6.515H7.897V25.868z"
          />
          <line
            fill="none"
            stroke="#010101"
            strokeWidth="7.5"
            strokeMiterlimit="10"
            x1="41.706"
            y1="45.423"
            x2="41.706"
            y2="0"
          />
          <path
            fill="#010101"
            d="M58.011,30.382c0-4.701,3.324-9.022,8.841-9.022c5.517,0,8.831,4.322,8.831,9.022s-3.314,9.023-8.831,9.023
	C61.334,39.405,58.011,35.083,58.011,30.382 M50.488,30.382c0,9.465,7.267,15.789,16.364,15.789c9.086,0,16.364-6.323,16.364-15.789
	s-7.277-15.798-16.364-15.798C57.755,14.583,50.488,20.917,50.488,30.382"
          />
          <path
            fill="#010101"
            d="M94.131,30.382c0-4.701,3.324-9.022,8.841-9.022c5.517,0,8.831,4.322,8.831,9.022s-3.314,9.023-8.831,9.023
	C97.455,39.405,94.131,35.083,94.131,30.382 M86.608,30.382c0,9.465,7.268,15.789,16.364,15.789c9.086,0,16.364-6.323,16.364-15.789
	s-7.277-15.798-16.364-15.798C93.875,14.583,86.608,20.917,86.608,30.382"
          />
          <path
            fill="#010101"
            d="M123.918,15.336h7.149v4.706h0.118c1.318-2.822,4.199-5.463,9.214-5.463c4.642,0,7.848,1.814,9.411,5.581
	c2.193-3.816,5.389-5.581,9.893-5.581c8.024,0,10.848,5.713,10.848,12.922v17.923h-7.523V28.376c0-3.757-1.121-7.017-5.576-7.017
	c-4.7,0-6.461,3.884-6.461,7.764v16.3h-7.513V27.5c0-3.708-1.505-6.141-5.143-6.141c-4.947,0-6.894,3.624-6.894,7.641v16.423h-7.523
	V15.336z"
          />
          <path
            fill="#010101"
            d="M201.597,30.382c0,4.706-3.314,9.023-8.841,9.023c-5.518,0-8.841-4.317-8.841-9.023
	c0-4.701,3.323-9.033,8.841-9.033C198.283,21.349,201.597,25.681,201.597,30.382 M176.648,45.423h7.149v-4.51h0.118
	c2.202,3.624,6.146,5.257,10.354,5.257c9.264,0,14.85-6.894,14.85-15.789c0-8.9-5.959-15.798-14.544-15.798
	c-5.636,0-8.654,2.626-10.218,4.583h-0.187V0h-7.523V45.423z"
          />
          <path
            fill="#010101"
            d="M220.045,27.373c0.561-4.332,3.324-7.149,7.769-7.149c4.701,0,7.582,2.759,7.651,7.149H220.045z
	 M242.988,33.013v-2.065c0-10.473-5.703-16.364-14.102-16.364c-9.106,0-16.364,6.333-16.364,15.798s7.258,15.789,16.364,15.789
	c4.75,0,9.214-1.691,12.715-6.009l-5.389-4.066c-1.878,2.307-4.327,4.066-8.084,4.066c-4.198,0-7.592-2.699-8.083-7.149H242.988z"
          />
          <path
            fill="#010101"
            d="M247.885,15.336h7.523v4.765h0.128c1.632-3.447,4.946-5.522,8.899-5.522c0.944,0,1.819,0.192,2.694,0.447
	v7.268c-1.249-0.31-2.448-0.561-3.639-0.561c-7.08,0-8.083,5.959-8.083,7.592v16.099h-7.523V15.336z"
          />
          <path
            fill="#010101"
            d="M283.957,21.359c5.448,0,8.899,3.624,8.899,8.84c0,5.006-3.383,8.831-8.959,8.831
	c-5.074,0-8.841-3.948-8.841-8.713C275.056,24.983,278.685,21.359,283.957,21.359 M292.856,15.336v4.514h-0.118
	c-2.202-3.639-6.155-5.271-10.354-5.271c-9.273,0-14.85,6.898-14.85,15.803c0,8.516,5.959,15.415,14.978,15.415
	c3.766,0,7.65-1.436,9.854-4.381h0.107v2.198c0,5.949-2.242,10.09-9.519,10.09c-4.199,0-7.337-1.574-10.346-4.347l-4.504,6.155
	c4.248,3.776,9.332,4.957,14.918,4.957c11.723,0,16.983-6.903,16.983-17.741V15.336H292.856z"
          />
        </g>
      </svg>
    </Wrapper>
  );
}

export default Bloomberg;
