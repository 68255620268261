import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.span``;

function BusinessInsider() {
  return (
    <Wrapper>
      <svg
        className="prefix__svg-icon prefix__logo-insider-new prefix__logo-vertically-center"
        width={308}
        height={'100%'}
        viewBox="0 0 308 48"
      >
        <path
          d="M0 1.594h7.638V46.42H0zm61.883 28.668L30.854.903v45.516h7.344V17.257l31.029 29.855V1.594h-7.344zm47.205-8.811l-7.269-2.629c-3.746-1.312-5.877-2.628-5.877-5.912 0-3.65 3.38-5.403 7.932-5.403 5.287 0 9.401 1.971 13.145 4.819V4.513c-3.597-2.335-8.224-3.65-13.292-3.65-8.299 0-15.202 4.307-15.202 12.192 0 7.373 4.553 10.586 10.575 12.776l6.536 2.337c4.039 1.533 6.316 2.921 6.316 6.424 0 4.015-3.599 5.841-8.74 5.841-5.286 0-10.208-1.897-14.321-5.184v7.958c3.159 2.191 8.299 3.943 14.321 3.943 9.182 0 16.157-4.528 16.157-12.704 0-6.497-3.23-10.44-10.281-12.995zm27.488-19.857h7.637V46.42h-7.637zm48.529.001h-17.296V46.42h17.296c13.293 0 21.886-8.761 21.886-22.414 0-13.651-8.594-22.411-21.886-22.411zm-.294 38.037h-9.363V8.311h9.363c9.471 0 14.54 6.059 14.54 15.695 0 9.563-5.068 15.626-14.54 15.626zm39.514 6.788h27.354v-6.788h-19.864V25.74h17.807v-6.644h-17.807V8.31h19.864V1.594h-27.354zm66.993-18.471c7.124-1.095 10.483-5.403 10.483-12.923 0-8.76-6.021-13.432-15.349-13.432h-16.358V46.42h7.637V28.241h5.435l15.698 18.179h8.666l-16.212-18.471zm-13.586-6.06V8.164h8.573c5.067 0 7.932 2.701 7.932 6.862 0 4.162-2.865 6.863-7.932 6.863h-8.573z"
          fill="#111"
          fillRule="evenodd"
        />
      </svg>
    </Wrapper>
  );
}

export default BusinessInsider;
