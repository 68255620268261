import React from 'react';
import styled, { withTheme } from 'styled-components';
import useDimensions from '../../hooks/useDimensions';

import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { Section } from '@components/Layout';
import { H1, H6, Bold } from '@components/Text';

import { RoundBox } from '@components/Blocks';
import { Image } from '@components/Images';

import UI1 from '@public/images/UI1@3x.png';
import UI2 from '@public/images/UI2@3x.png';
import UI3 from '@public/images/UI3@3x.png';
import UI4 from '@public/images/UI4@3x.png';
import Whiteboarding from '@public/images/whiteboarding.png';
import ReadingOnBooks from '@public/images/reading_on_books.png';
import PushingButton from '@public/images/pushing_button.png';

const MobileWrapper = styled.div`
  /* div.section-container {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (max-width: 600px) {
    div.section-container {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media (max-width: 430px) {
    div.section-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  } */
`;

const Box1Content = ({ isMobile }) => {
  return (
    <Row position="relative" vAlign="bottom" height="100%" width="100%">
      <Place zIndex="1">
        <H6>
          <Bold>Join conversations with leading investors</Bold>
        </H6>
      </Place>

      <Place
        position="absolute"
        top="-90px"
        left={isMobile ? '-50px' : '-70px'}
        zIndex="52"
        width={isMobile ? '210px' : '205px'}
      >
        <Image src={UI1} alt="" useNextImage={true} />
      </Place>
      <Place
        position="absolute"
        top="-15px"
        left={isMobile ? '-50px' : '-60px'}
        zIndex="54"
        width={isMobile ? '210px' : '210px'}
      >
        <Image src={UI2} alt="" useNextImage={true} />
      </Place>
      <Place
        position="absolute"
        top={isMobile ? '30px' : '10px'}
        right={isMobile ? '-10px' : '-20px'}
        zIndex="0"
        width={isMobile ? '190px' : '160px'}
      >
        <Image src={Whiteboarding} alt="" useNextImage={true} />
      </Place>
    </Row>
  );
};

const Box2Content = ({ isMobile }) => {
  return (
    <Row position="relative" vAlign="bottom" height="100%" width="100%">
      <Place zIndex="1" width="210px">
        <H6>
          <Bold>Get news tailored to your stocks</Bold>
        </H6>
      </Place>
      <Place
        position="absolute"
        top={isMobile ? '-65px' : '-140px'}
        left={isMobile ? '-55px' : '-25px'}
        zIndex="1"
        width="300px"
      >
        <Image src={UI3} alt="" useNextImage={true} />
      </Place>
      <Place
        position="absolute"
        top={isMobile ? '60px' : '20px'}
        right={isMobile ? '-38px' : '0px'}
        zIndex="0"
        width={isMobile ? '210px' : '176px'}
      >
        <Image src={ReadingOnBooks} alt="" useNextImage={true} />
      </Place>
    </Row>
  );
};

const Box3Content = ({ isMobile }) => {
  return (
    <Row position="relative" vAlign="bottom" height="100%" width="100%">
      <Place zIndex="1" width="270px">
        <H6>
          <Bold>Optimize your portfolio in an instant</Bold>
        </H6>
      </Place>
      <Place position="absolute" top="-20px" right={isMobile ? '-114px' : '-70px'} zIndex="1" width="240px">
        <Image src={UI4} alt="" useNextImage={true} />
      </Place>
      <Place position="absolute" top="0px" left={isMobile ? '-40px' : '-40px'} zIndex="0" width="176px">
        <Image src={PushingButton} alt="" useNextImage={true} />
      </Place>
    </Row>
  );
};

const FeatureOverviewFull = () => {
  const [ref, dimensions] = useDimensions();
  const { width } = dimensions;

  const margin = 28 * 2;
  const boxWidth = (width - margin) / 3;

  return (
    <Column className="section-container" padding="200px 100px 200px 100px">
      <Row>
        <Place maxWidth="850px">
          <H1>
            <Bold>
              Get the
              <br />
              money-making
            </Bold>{' '}
            kind of green thumb{' '}
          </H1>
        </Place>
      </Row>
      <Row padding="215px 0 0 0" hAlign="center">
        <Place style={{ width: '100%', maxWidth: '1050px' }}>
          <div ref={ref} style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
              <RoundBox width={`${boxWidth}px`} height={`${boxWidth}px`} margin="0 28px 0 0" padding="20px">
                <Box1Content />
              </RoundBox>
              <RoundBox width={`${boxWidth}px`} height={`${boxWidth}px`} margin="80px 28px 0 0" padding="20px">
                <Box2Content />
              </RoundBox>
              <RoundBox width={`${boxWidth}px`} height={`${boxWidth}px`} margin="160px 0 0 0" padding="20px">
                <Box3Content />
              </RoundBox>
            </Row>
          </div>
        </Place>
      </Row>
    </Column>
  );
};

const FeatureOverviewMobile = () => {
  const [ref, dimensions] = useDimensions();
  const { width } = dimensions;
  const boxWidth = Math.min(width, 600);
  return (
    <MobileWrapper>
      <Column className="section-container" padding="100px 0 0 0" hAlign="center" width="100%">
        <Place maxWidth="350px">
          <div ref={ref}>
            <H1>
              <Bold>
                Get the
                <br />
                money&#8209;making
              </Bold>
              <br />
              kind of green
              <br />
              thumb
            </H1>
            <Column padding="115px 0 0 0" hAlign="left">
              <RoundBox
                vAlign="bottom"
                zIndex="1"
                height={`${boxWidth}px`}
                width={'100%'}
                margin="0 0 60px 0"
                padding="20px"
              >
                <Box1Content isMobile />
              </RoundBox>
              <RoundBox
                vAlign="bottom"
                zIndex="1"
                height={`${boxWidth}px`}
                width={'100%'}
                margin="0 0 60px 0"
                padding="20px"
              >
                <Box2Content isMobile />
              </RoundBox>
              <RoundBox vAlign="bottom" zIndex="1" height={`${boxWidth}px`} width={'100%'} padding="20px">
                <Box3Content isMobile />
              </RoundBox>
            </Column>
          </div>
        </Place>
      </Column>
    </MobileWrapper>
  );
};

const FeatureOverviewMain = ({ theme }) => {
  const { isMobile } = theme;
  return <Section>{isMobile ? <FeatureOverviewMobile /> : <FeatureOverviewFull />}</Section>;
};

export default withTheme(FeatureOverviewMain);
