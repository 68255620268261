import React from 'react';
import Link from 'next/link';

import { Disclaimer } from '@components/Text/Disclaimer';
import { PATHS } from '@constants/paths';
import Anchor from '@components/Primitives/Anchor';

export const SignupRewardDisclaimer = () => {
  return (
    <Disclaimer>
      For a limited time only. Offer does not apply to simulated trading accounts. Other restrictions apply. See the{' '}
      <Link href={PATHS.FUNDING_BONUS_RULES}>Official Rules</Link> for details.
    </Disclaimer>
  );
};
