import React from 'react';
import { Row, Column } from '@lib/nvstr-common-ui.es';
import useDimensions from 'hooks/useDimensions';
import Image from '@components/Images/Image';

const HeadingImageBulletsSideBySide = ({
  headingComponent,
  subHeadingComponent,
  bulletsComponent,
  imageData,
  isImageLeft,
  cta,
}) => {
  const [ref, dimensions] = useDimensions(null, true);
  const { width } = dimensions;
  const imageWidth = width / 2;
  const ratio = 606 / 616;
  const imageHeight = ratio * imageWidth;
  const rightPhoneEdgeLeftPos = imageWidth - 100;

  return (
    <div ref={ref} style={{ width: '100%' }}>
      {isImageLeft ? (
        <Column padding={`0 0 0 ${rightPhoneEdgeLeftPos}px`}>
          <Row>{headingComponent}</Row>
          <Row padding="40px 0 0 0">{subHeadingComponent}</Row>
        </Column>
      ) : (
        <>
          <Row>{headingComponent}</Row>
          <Row padding="40px 0 0 0">{subHeadingComponent}</Row>
        </>
      )}

      <Row width="100%" padding="68px 0 0 0">
        {isImageLeft ? (
          <>
            <Column width="50%">
              <div>
                <Image alt="" {...imageData} useNextImage={true} />
              </div>
            </Column>
            <Column width="50%" height={`${imageHeight}px`} vAlign="bottom" padding="0 0 0 10%">
              <Column>{bulletsComponent}</Column>
              {cta ? <Column padding="52px 0 0 0 ">{cta}</Column> : <Column padding="52px 0 0 0" />}
            </Column>
          </>
        ) : (
          <>
            <Column width="50%" height={`${imageHeight}px`} vAlign="bottom" padding="0 10% 0 0">
              <Column>{bulletsComponent}</Column>
              {cta ? <Column padding="52px 0 0 0 ">{cta}</Column> : <Column padding="52px 0 0 0" />}
            </Column>
            <Column width="50%">
              <div>
                <Image alt="" {...imageData} useNextImage={true} />
              </div>
            </Column>
          </>
        )}
      </Row>
    </div>
  );
};

export default HeadingImageBulletsSideBySide;
