import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Section } from '@components/Layout';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { H2, Body1, Body2, Eyebrow, Bold } from '@components/Text';
import AppStore from '@components/Images/AppStore';
import GooglePlay from '@components/Images/GooglePlay';
import { useWindowSize } from '@hooks/useWindowSize';
import CheckCircle from '../Icons/ui/CheckCircle';
import { parseQueryString } from '@utils/usefulFuncs';
import SignUpForm from '../Form/SignUpForm';
import { SignupRewardDisclaimer } from '@components/Disclaimers/SignupRewardDisclaimer';
import { Image } from '@components/Images';

import phoneHero from '@public/images/phoneHero1.png';

const MobileHeadingWrapper = styled.div`
  video {
    min-height: 100%;
    min-width: 100%;
    height: auto !important;
    width: auto !important;
    max-width: 100%;
  }

  @media (min-width: 500px) {
    h2 {
      font-size: 1.5em;
      line-height: 120%;
    }

    p {
      font-size: 1.3em;
      line-height: 120%;
    }
  }

  @media (max-width: 625px) {
    .heading-wrapper {
      max-width: 500px;
    }
  }

  @media (max-width: 425px) {
    .heading-wrapper {
      max-width: 360px;

      p {
        line-height: 140%;
      }
    }
  }

  @media (max-width: 360px) {
    h2 {
      font-size: 0.8em;
      line-height: 120%;
    }

    p {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const HeroMobile = ({ windowWidth, showAppStoreLinks, imageTitle }) => {
  const showEmailSignup = true;
  const showAppStoreLinksOverVideo = false;
  return (
    <Section>
      <Column padding="20px 20px 0px 20px" maxWidth="100%">
        <MobileHeadingWrapper>
          <div className="heading-wrapper">
            <Column maxWidth="600px" margin="0 auto">
              <Place zIndex="1" width="100%">
                <H2>
                  <Bold>Invest in yourself</Bold>
                </H2>
              </Place>

              <Place padding="8px 0 0 0" width="90%" zIndex="1">
                <Body2>
                  Buy, sell, and trade with Tornado, the only investing platform that helps you invest in yourself.
                </Body2>
              </Place>
            </Column>
          </div>

          <div
            style={{
              position: 'relative',
              maxWidth: '660px',
              margin: '0 auto',
            }}
          >
            {imageTitle ? (
              <Image useNextImage={true} imageTitle={imageTitle} />
            ) : (
              <Image src={phoneHero} alt="" useNextImage={true} />
            )}

            {showEmailSignup ? (
              <Place position="absolute" bottom="10px" left="0px" width="100%">
                <SignUpForm isEmbedded />
              </Place>
            ) : (
              showAppStoreLinksOverVideo && (
                <Place position="absolute" bottom="10%" left="0px" width="100%">
                  <Row hAlign="center">
                    <Column>
                      <AppStore
                        width={
                          windowWidth > 600
                            ? '250px'
                            : windowWidth < 380
                            ? windowWidth < 360
                              ? '130px'
                              : '150px'
                            : '184px'
                        }
                        isDarkMode={true}
                        isDarkBg={true}
                      />
                    </Column>
                    <Column padding="0 4px" />
                    <Column padding="0 0 0 0">
                      <GooglePlay
                        width={
                          windowWidth > 600
                            ? '250px'
                            : windowWidth < 380
                            ? windowWidth < 360
                              ? '130px'
                              : '150px'
                            : '184px'
                        }
                        isDarkMode={true}
                        isDarkBg={true}
                      />
                    </Column>
                  </Row>
                </Place>
              )
            )}
          </div>

          <Place padding="4px 0 0 0">
            <Row hAlign="center">
              <Place padding="1px 12px 0px 0" maxWidth="90%">
                <CheckCircle width={18} />
              </Place>
              <Eyebrow>Up to $1,000 cash when you make a deposit</Eyebrow>
            </Row>
            <Row hAlign="center">
              <Place padding="12px 0 0 0" maxWidth="436px">
                <SignupRewardDisclaimer />
              </Place>
            </Row>
          </Place>

          {showAppStoreLinks && (
            <Row hAlign="center" padding="24px 0 0 0">
              <Column>
                <AppStore
                  width={
                    windowWidth > 600 ? '250px' : windowWidth < 380 ? (windowWidth < 360 ? '130px' : '150px') : '184px'
                  }
                  isDarkMode={true}
                  isDarkBg={true}
                />
              </Column>
              <Column padding="0 4px"></Column>
              <Column padding="0 0 0 0">
                <GooglePlay
                  width={
                    windowWidth > 600 ? '250px' : windowWidth < 380 ? (windowWidth < 360 ? '130px' : '150px') : '184px'
                  }
                  isDarkMode={true}
                  isDarkBg={true}
                />
              </Column>
            </Row>
          )}
        </MobileHeadingWrapper>
      </Column>
    </Section>
  );
};

const HeroFull = ({ windowWidth, windowHeight, imageTitle }) => {
  const headerHeight = 120;
  const usableHeight = windowHeight - headerHeight;

  const imageAspectRatio = 1;
  const imageWidth = Math.min(usableHeight * imageAspectRatio, 690);
  const imageWidthPx = `${imageWidth}px`;
  const imageLeftOffset = Math.max(740 - imageWidth, 0);

  return (
    <Section>
      <Place position="relative" style={{ minHeight: `${Math.min(usableHeight, 900)}px` }}>
        <Column className="section-container" padding="70px 100px 10px 100px">
          <Place position="relative" zIndex="2" style={{ maxWidth: '630px' }}>
            <H2>
              <Bold>Invest in yourself</Bold>
            </H2>

            <Place padding="50px 0 50px" width="500px">
              <Body1>
                Buy, sell, and trade with Tornado, the only investing platform that helps you invest in yourself.{' '}
              </Body1>
            </Place>

            <Place padding="0 0 20px 0" width="400px">
              <SignUpForm isEmbedded={true} />
              <Row padding="20px 0 0 0">
                <Place padding="1px 8px 0px">
                  <CheckCircle width={17} />
                </Place>
                <Eyebrow>Up to $1,000 cash when you make a deposit</Eyebrow>
              </Row>
              <Row padding="12px 0 0 8px">
                <SignupRewardDisclaimer />
              </Row>
            </Place>
          </Place>
        </Column>

        <Place
          position="absolute"
          top={usableHeight > 850 ? '150px' : '25px'}
          left={windowWidth < 1100 ? `${490 - 100 + imageLeftOffset / 2}px` : `${490 + imageLeftOffset / 2}px`}
          zIndex="1"
          width={imageWidthPx}
        >
          {imageTitle ? (
            <Image useNextImage={true} alt="" imageTitle={imageTitle} />
          ) : (
            <Image src={phoneHero} alt="" useNextImage={true} />
          )}
        </Place>
      </Place>
    </Section>
  );
};

const HeroDesktopSmall = ({ windowWidth, windowHeight, imageTitle }) => {
  const headerHeight = 120;
  const usableHeight = windowHeight - headerHeight;

  const imageAspectRatio = 1;
  const imageWidth = Math.min(usableHeight * imageAspectRatio, 690);
  const imageWidthPx = `${imageWidth}px`;
  const imageLeftOffset = Math.max(740 - imageWidth, 0);

  return (
    <Section>
      <Place position="relative" style={{ minHeight: `${Math.min(usableHeight, 900)}px` }}>
        <Column className="section-container" padding="40px 100px 10px 100px">
          <Place position="relative" zIndex="2" style={{ maxWidth: '630px' }}>
            <H2>
              <Bold>Invest in yourself</Bold>
            </H2>

            <Place padding="20px 0" width="500px">
              <Body1>
                Buy, sell, and trade with Tornado, the only investing platform that helps you invest in yourself.{' '}
              </Body1>
            </Place>

            <Row vAlign="center">
              <Place padding="12px 0 20px 0px" width="400px">
                <SignUpForm isEmbedded={true} />
                <Row padding="20px 0 0 0">
                  <Place padding="1px 8px 0px">
                    <CheckCircle width={17} />
                  </Place>
                  <Eyebrow>Up to $1,000 cash when you make a deposit</Eyebrow>
                </Row>
                <Row padding="12px 0 0 8px">
                  <SignupRewardDisclaimer />
                </Row>
              </Place>
            </Row>
          </Place>
        </Column>

        <Place
          position="absolute"
          top={usableHeight > 850 ? '150px' : '10px'}
          left={windowWidth < 1100 ? `${490 - 100 + imageLeftOffset / 2}px` : `${490 + imageLeftOffset / 2}px`}
          zIndex="1"
          width={imageWidthPx}
        >
          {imageTitle ? (
            <Image useNextImage={true} imageTitle={imageTitle} />
          ) : (
            <Image src={phoneHero} alt="" useNextImage={true} />
          )}
        </Place>
      </Place>
    </Section>
  );
};

const Hero = ({ theme, imageTitle }) => {
  // const { imageTitle } = props;
  const [showAppStoreLinks, setShowAppStoreLinks] = React.useState(false);

  React.useEffect(() => {
    const query = parseQueryString(window.location.search);
    if (query.appStoreCfg != 2) {
      setShowAppStoreLinks(true);
    }
  }, []);

  const { isMobile } = theme;

  const { width, height } = useWindowSize();
  const isSmallDesktopScreen = !isMobile && height < 850;

  return (
    <Section>
      {isMobile ? (
        <HeroMobile
          theme={theme}
          windowWidth={width}
          windowHeight={height}
          showAppStoreLinks={showAppStoreLinks}
          imageTitle={imageTitle}
        />
      ) : isSmallDesktopScreen ? (
        <HeroDesktopSmall theme={theme} windowWidth={width} windowHeight={height} imageTitle={imageTitle} />
      ) : (
        <HeroFull theme={theme} windowWidth={width} windowHeight={height} imageTitle={imageTitle} />
      )}
    </Section>
  );
};

export default withTheme(Hero);
