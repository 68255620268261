import React from 'react';

import { Row, Column } from '@lib/nvstr-common-ui.es';

const BulletBox = ({ leftBox, rightBox }) => {
  return (
    <Row margin="180px 0 0 0">
      <Column width="50%">{leftBox}</Column>
      <Column width="50%">{rightBox}</Column>
    </Row>
  );
};

export default BulletBox;
