import React from 'react';
import styled, { withTheme } from 'styled-components';
import Link from 'next/link';
import { Row, Column } from '@lib/nvstr-common-ui.es';
import { Section } from '@components/Layout';
import { CNBC, Bloomberg, Forbes, UsNews } from '@components/Images/logos';
import { PATHS } from '@constants/paths';

const PressMentionsDesktopWrapper = styled(Column)`
  svg,
  image,
  img,
  path {
    width: 200px;
  }

  @media (max-width: 1200px) {
    svg,
    image,
    img,
    path {
      width: 165px;
    }
  }
`;

const PressMentionsMobileWrapper = styled(Column)`
  svg,
  image,
  img,
  path {
    width: 200px;
  }

  @media (max-width: 525px) {
    svg,
    image,
    img,
    path {
      width: 120px;
    }
  }

  @media (max-width: 360px) {
    svg,
    image,
    img,
    path {
      width: 105px;
    }
  }
`;

const PressMentionsMobile = ({ theme }) => {
  return (
    <PressMentionsMobileWrapper
      padding="36px 20px"
      margin="50px 0 0 0"
      border={`1px solid ${theme.secondary.silver}`}
      borderStyle="solid none solid none"
      width="100%"
      height="224px"
    >
      <Row height="100%" vAlign="center" hAlign="center">
        <Column width="100%" height="100%" vAlign="center" hAlign="center">
          <Link
            href={PATHS.pressMentionBloomberg}
            target="_blank"
            className="no-text-decoration"
            rel="noopener noreferrer"
          >
            <Bloomberg />
          </Link>
        </Column>
        <Column width="100%" height="100%" vAlign="center" hAlign="center">
          <Link
            href={PATHS.pressMentionForbes}
            target="_blank"
            className="no-text-decoration"
            rel="noopener noreferrer"
          >
            <Forbes />
          </Link>
        </Column>
      </Row>
      <Row height="100%" padding="36px 0 0 0" vAlign="center" hAlign="center">
        <Column width="100%" height="100%" vAlign="center" hAlign="center">
          <Link href={PATHS.pressMentionCNBC} target="_blank" className="no-text-decoration" rel="noopener noreferrer">
            <CNBC />
          </Link>
        </Column>
        <Column width="100%" height="100%" vAlign="center" hAlign="center">
          <Link
            href={PATHS.pressMentionUsNews}
            target="_blank"
            className="no-text-decoration"
            rel="noopener noreferrer"
          >
            <UsNews />
          </Link>
        </Column>
      </Row>
    </PressMentionsMobileWrapper>
  );
};

const PressMentionsFull = ({ theme }) => {
  return (
    <PressMentionsDesktopWrapper
      border={`1px solid ${theme.primary.charcoal}`}
      borderStyle="solid none solid none"
      height="200px"
    >
      <Row height="100%">
        <Column width="25%" height="100%" vAlign="center" hAlign="center">
          <Link
            href={PATHS.pressMentionBloomberg}
            target="_blank"
            className="no-text-decoration"
            rel="noopener noreferrer"
          >
            <Bloomberg />
          </Link>
        </Column>
        <Column width="25%" height="100%" vAlign="center" hAlign="center">
          <Link
            href={PATHS.pressMentionForbes}
            target="_blank"
            className="no-text-decoration"
            rel="noopener noreferrer"
          >
            <Forbes />
          </Link>
        </Column>

        <Column width="25%" height="100%" vAlign="center" hAlign="center">
          <Link href={PATHS.pressMentionCNBC} target="_blank" className="no-text-decoration" rel="noopener noreferrer">
            <CNBC />
          </Link>
        </Column>
        <Column width="25%" height="100%" vAlign="center" hAlign="center">
          <Link
            href={PATHS.pressMentionUsNews}
            target="_blank"
            className="no-text-decoration"
            rel="noopener noreferrer"
          >
            <UsNews />
          </Link>
        </Column>
      </Row>
    </PressMentionsDesktopWrapper>
  );
};

const PressMentionsMain = ({ theme }) => {
  const { isMobile } = theme;
  return (
    <Section>
      <Column className="section-container" padding="0 100px">
        {isMobile ? <PressMentionsMobile theme={theme} /> : <PressMentionsFull theme={theme} />}
      </Column>
    </Section>
  );
};

export default withTheme(PressMentionsMain);
