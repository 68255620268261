import { ApolloClient, InMemoryCache } from '@apollo/client';

const contentfulLanderSpaceId = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const contentfulLanderSpaceAccessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
const contentfulLanderDataURI = `https://graphql.contentful.com/content/v1/spaces/${contentfulLanderSpaceId}/?access_token=${contentfulLanderSpaceAccessToken}`;
export const contentfulClient = new ApolloClient({
  uri: contentfulLanderDataURI,
  cache: new InMemoryCache(),
  credentials: 'same-origin',
});

const strapiAPIUrl = process.env.NEXT_PUBLIC_STRAPI_URL;
const strapiAccessToken = process.env.NEXT_PUBLIC_STRAPI_KEY;
export const strapiClient = new ApolloClient({
  uri: `${strapiAPIUrl}`,
  cache: new InMemoryCache(),
  credentials: 'same-origin',
});
