import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  g {
    fill: ${(props) =>
      props.isDarkMode
        ? props.theme.secondary.white
        : props.theme.primary.charcoal};
  }
`;

const CheckCircle = ({ isDarkMode, width }) => {
  const ratio = 18 / 17;
  const height = width * ratio;
  return (
    <Wrapper isDarkMode={isDarkMode}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M8.49935 0.666626C3.89935 0.666626 0.166016 4.39996 0.166016 8.99996C0.166016 13.6 3.89935 17.3333 8.49935 17.3333C13.0993 17.3333 16.8327 13.6 16.8327 8.99996C16.8327 4.39996 13.0993 0.666626 8.49935 0.666626ZM8.49935 15.6666C4.82435 15.6666 1.83268 12.675 1.83268 8.99996C1.83268 5.32496 4.82435 2.33329 8.49935 2.33329C12.1743 2.33329 15.166 5.32496 15.166 8.99996C15.166 12.675 12.1743 15.6666 8.49935 15.6666ZM12.3243 5.31663L6.83268 10.8083L4.67435 8.65829L3.49935 9.83329L6.83268 13.1666L13.4993 6.49996L12.3243 5.31663Z" />
        </g>
      </svg>
    </Wrapper>
  );
};

export default CheckCircle;
