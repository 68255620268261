export { PATHS } from './paths';
export { EMAIL_ADDRESSES } from './emailAddresses';

export const membershipPrice = '9.99';
export const commissionAmount = '4.50';
export const commissionFreeTradeAmount = '50';

export const ELEMENT_SIZES = {
  headerNavbar: 64,
};
