import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span``;

function ForbesLogo() {
  return (
    <Wrapper>
      <svg viewBox="1.91 -25.333 192.757 74.754">
        <path fill="transparent" d="M1.91-25.333h192.757v74.755H1.91z" />
        <path
          d="M114.738 1.027c-2.424 0-4.328.52-6.233 1.558 0-5.541 0-10.562.174-12.293L97.076-7.631v1.039l1.212.173c1.557.346 2.079 1.211 2.422 3.29.349 4.156.174 26.321 0 29.958 3.117.693 6.408 1.213 9.699 1.213 9.178 0 14.719-5.715 14.719-14.72 0-7.099-4.502-12.295-10.39-12.295zm-3.983 25.109c-.693 0-1.56 0-2.078-.172-.174-2.424-.346-12.642-.174-21.646 1.039-.347 1.731-.52 2.598-.52 3.812 0 5.89 4.502 5.89 10.042-.001 6.929-2.599 12.296-6.236 12.296zM53.436-9.016H22.958v1.558l1.732.173c2.25.346 3.117 1.731 3.462 5.021.521 6.234.347 17.491 0 22.857-.346 3.291-1.211 4.85-3.462 5.021l-1.732.348v1.385h19.568v-1.385l-2.078-.348c-2.251-.172-3.117-1.73-3.463-5.021-.174-2.252-.347-5.889-.347-10.39l4.156.173c2.598 0 3.809 2.078 4.329 5.021h1.559V3.106h-1.559c-.521 2.944-1.731 5.021-4.329 5.021l-4.155.174c0-5.888.173-11.429.347-14.719h6.06c4.677 0 7.101 2.943 8.832 8.139l1.732-.52-.174-10.217z"
          fill="#222"
        />
        <path
          d="M63.307.681c8.659 0 12.988 5.888 12.988 13.68 0 7.446-4.849 13.682-13.508 13.682-8.658 0-12.987-5.889-12.987-13.682 0-7.446 4.848-13.68 13.507-13.68zm-.52 1.732c-3.81 0-4.849 5.195-4.849 11.948 0 6.58 1.732 11.948 5.196 11.948 3.98 0 5.021-5.195 5.021-11.948 0-6.58-1.73-11.948-5.368-11.948zm64.591 12.122c0-7.1 4.502-13.854 13.334-13.854 7.271 0 10.736 5.369 10.736 12.468h-16.105c-.174 6.407 2.943 11.083 9.178 11.083 2.771 0 4.156-.691 5.889-1.904l.691.865c-1.732 2.424-5.541 4.85-10.389 4.85-7.793-.001-13.334-5.542-13.334-13.508zm7.965-3.29l8.139-.174c0-3.463-.52-8.658-3.463-8.658s-4.504 4.848-4.676 8.832zm36.715-9.178C169.98 1.2 167.21.681 164.093.681c-6.406 0-10.391 3.809-10.391 8.312s2.941 6.407 7.102 7.793c4.328 1.557 5.541 2.77 5.541 4.848s-1.559 3.982-4.33 3.982c-3.289 0-5.715-1.904-7.619-7.1l-1.213.348.174 7.619c2.078.865 5.887 1.559 9.178 1.559 6.754 0 11.084-3.465 11.084-8.832 0-3.637-1.906-5.714-6.408-7.447-4.85-1.904-6.58-3.116-6.58-5.368 0-2.251 1.561-3.81 3.639-3.81 3.115 0 5.193 1.905 6.754 6.581l1.211-.346-.177-6.753zm-73.252-.693c-2.943-1.73-8.139-.866-10.909 5.369l.172-6.061-11.601 2.251v1.039l1.212.173c1.56.174 2.251 1.039 2.425 3.291.346 4.156.173 11.43 0 15.066-.174 2.076-.865 3.115-2.425 3.289l-1.212.174v1.385h16.104v-1.385l-2.078-.174c-1.731-.174-2.251-1.213-2.425-3.289-.346-3.291-.346-9.871-.172-14.027.865-1.213 4.675-2.251 8.138 0l2.771-7.101z"
          fill="#222"
        />
      </svg>
    </Wrapper>
  );
}

export default ForbesLogo;
