import React from 'react';
import Img from 'next/legacy/image';
import { contentfulClient } from '@utils/apolloClients';
import { useQuery, gql } from '@apollo/client';
import { isDev } from '@utils/isDev';

const GET_IMAGE = gql`
  query SlugIndex($preview: Boolean, $where: AssetFilter) {
    assetCollection(preview: $preview, where: $where) {
      items {
        title
        url
        width
        height
        sys {
          id
        }
      }
    }
  }
`;

function ImageCMS({ imageTitle }) {
  function contentfulLoader({ src, width, quality }) {
    return `${src}?w=${width || 1200}&q=${quality || 75}`;
  }
  const { data, loading, error } = useQuery(GET_IMAGE, {
    variables: {
      where: { title: imageTitle },
      preview: isDev,
    },
    client: contentfulClient,
  });

  if (loading) {
    return null;
  }
  if (error) {
    console.error(error);
    return null;
  }

  const imageQueryResult = data.assetCollection.items;
  const defaultProps = {};

  return imageQueryResult.map((image) => (
    <Img
      key={image.sys.id}
      src={image.url}
      alt={image.title}
      quality={80}
      unoptimized={false}
      width={image.width}
      height={image.height}
      {...defaultProps}
      loader={contentfulLoader}
    />
  ));
}

export default ImageCMS;
